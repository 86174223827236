<template>
  <div class="main-wrapper">
    <!-- <title-bar title="Finding You The Fun At Bars & Restaurants Across America Across The Street" subtitle=""></title-bar> -->
    <div
      id="about_us"
      class="gradient"
    >
      <div class="title-bar-inner">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h3 style="color:#ffffff;font-weight:bold;font-size:30px;">
                About Us
              </h3>
              <!-- <span>{{subtitle}}</span> -->
              <!-- Breadcrumbs -->
              <!-- <nav id="breadcrumbs">
							<ul>
								<li><a >Home</a></li>
								<li>Listings</li>
							</ul>
						</nav> -->
            </div>
          </div>
        </div>
      </div>
      <div class="title-overlay" />
    </div>
    <div class="content pt-0">
      <div class="about-wrapper">
        <div class="mb-3 mt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-10 ml-auto mr-auto">
                <div class="section-title text-center mb-5">
                  <h2>Who We Are ? </h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <p class="font-weight-normal f19">
                  Thriggle is based out of Houston Texas. Our platform connects users with local events at bars and restaurants in their area. 
                  The app provides a variety of convenient entertainment options for people of all tastes - live music, sports viewing, food specials, happy hours, and much more. 
                  Whatever “fun” means to you, we have you covered!
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Live Music Section -->
        <div class=" row">
          <div class="container">
            <h3
              class="text-center mb-5"
              style="color:#5e72e4;"
            >
              Thriggle's Live Music Program
            </h3>
            <div class="row row-grid align-items-center">
              <div class="col-md-6">
                <div class="pl-md-5">
                  <p class="f19 font-weight-normal text-justify">
                    There has never been a better time to get out and enjoy local music. After over a year of restrictions and closures, venues are finally opening up again.
                  </p>
                  <p class="f19 font-weight-normal text-justify">
                    The Thriggle Live Music Program connects you to thousands of bars, clubs, and concert halls across the country. 
                    Our program’s search and rating features allow you to find high quality music of all genres no matter where you live.
                  </p>
                  <!-- <a href="#" class="font-weight-bold text-warning mt-5">COMING FALL 2021 LIVE MUSIC</a> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                  <img
                    src="/static/images/live_music.jpg"
                    class="card-img-top"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Happy hour Program -->
        <div class="mt-4 row bg-secondary pb-5">
          <div class="container">
            <h3
              class="text-center mb-5 mt-5"
              style="color:#5e72e4;"
            >
              Thriggle's Happy Hour Program
            </h3>
            <div class="row row-grid align-items-center">
              <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                  <img
                    src="/static/images/happy_hour_main.jpg"
                    class="card-img-top"
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="pl-md-5">
                  <p class="f19 font-weight-normal text-justify">
                    Our Thriggle Happy Hour Program hooks users up with discounts from local bars and restaurants. If you’re looking for a happy hour, simply type in your zip code, or use the 'near me' map to find an event.
                  </p>
                  <p class="f19 font-weight-normal text-justify">
                    We rely on people like you to maintain an up-to-date inventory. If there’s Happy Hour at a bar or restaurant you enjoy, show some love by listing them on the site!
                  </p>
                  <!-- <a href="#" class="font-weight-bold text-warning mt-5">COMING FALL 2021 HAPPY HOUR</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Sponsored Events Program -->
        <!-- <div class="row pb-5">
          <div class="container">
            <h3
              class="text-center mb-5 mt-5"
              style="color:#5e72e4;"
            >
              Thriggle's Sponsored Events Program
            </h3>
            <div class="row row-grid align-items-center">
              <div class="col-md-6">
                <div class="pl-md-5">
                  <p class="f19 font-weight-normal text-justify">
                    Are you a bar, restaurant, or promoter looking to advertise an event? Our Thriggle Sponsored Events Program is the perfect opportunity to get the word out!
                  </p>
                  <p class="f19 font-weight-normal text-justify">
                    For only $5 a week you can advertise deals and promotions to people in your zip code. If you’re looking to grow your business we also offer personalized multi-location deals to reach more customers.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                  <img
                    src="/static/images/events.jpg"
                    class="card-img-top"
                  >
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!--  Games Program -->
        <div class="mt-4 row pb-5">
          <div class="container">
            <h3
              class="text-center mb-5 mt-5"
              style="color:#5e72e4;"
            >
              Thriggle's Games Program
            </h3>
            <div class="row row-grid align-items-center">
              <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                  <img
                    src="/static/images/game_main.jpg"
                    class="card-img-top"
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="pl-md-5">
                  <p class="f19 font-weight-normal text-justify">
                    Ordering beers and cocktails is just one part of going out. Spice things up by combining drink night and game night with our Thriggle Games Program!
                  </p>
                  <p class="f19 font-weight-normal text-justify">
                    The Games Program links you up with a variety of games, activities, and competitions in your area. It’s a cheap, easy way to get together with friends and have a good time. Keep an eye out for it in Summer 2021.
                  </p>
                  <!-- <a href="#" class="font-weight-bold text-warning mt-5" >COMING FALL 2021 GAMES PROGRAM</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Sports Bar Program -->
        <div class="mt-4 row pb-5 bg-secondary ">
          <div class="container">
            <h3
              class="text-center mb-5 mt-5"
              style="color:#5e72e4;"
            >
              Thriggle's Sports Bar Program
            </h3>
            <div class="row row-grid align-items-center">
              <div class="col-md-6">
                <div class="pl-md-5">
                  <p class="f19 font-weight-normal text-justify">
                    Need a spot to watch the game with your friends? Or maybe you’re in a new city and want a friendly place to cheer on your hometown team. Whatever the case, The Thriggle Sports Bar Program has you covered!
                  </p>
                  <p class="f19 font-weight-normal text-justify">
                    Our Sports Bar Program finds restaurants and bars nearby that feature the sports and teams you want to see. Don’t sit at home for another game, check out our Sports Bar Program in Summer 2022 !
                  </p>
                  <!-- <a href="#" class="font-weight-bold text-warning mt-5" >COMING FALL 2021 SPORTS BAR PROGRAM</a> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="card shadow border-0">
                  <img
                    src="/static/images/sports_main.jpg"
                    class="card-img-top"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Kid Friendly  Program -->
        <div class="mt-4 row pb-5">
          <div class="container">
            <h3
              class="text-center mb-5 mt-5"
              style="color:#5e72e4;"
            >
              Thriggle's Karaoke Program
            </h3>
            <div class="row row-grid align-items-center">
              <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                  <img
                    src="/static/images/karaoke_main.jpg"
                    class="card-img-top"
                  >
                </div>
              </div>
              <div class="col-md-6">
                <div class="pl-md-5">
                  <p class="f19 font-weight-normal text-justify">
                   Warm up your singing voice with Thriggle’s Karaoke Program! Our listings give you an up to the minute look at the Karaoke bars and events in your area. 
                   Whether you’re looking for a private room with your friends or a lively sing along with a packed bar, our program provides everything you need for a great night out Summer 2022.  
                  </p>
                  <!-- <a href="#" class="font-weight-bold text-warning mt-5" >COMING FALL 2021 KID FRIENDLY PROGRAM</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Ad Section Progeam -->
         <div class="mt-4 row pb-5 bg-secondary ">
          <div class="container">
            <h3
              class="text-center mb-5 mt-5"
              style="color:#5e72e4;"
            >
              Thriggle’s Events (Sponsored) Program
            </h3>
            <div class="row row-grid align-items-center">
              
              <div class="col-md-6">
                <div class="pl-md-5">
                  <p class="f19 font-weight-normal text-justify">
                 Want to get out of the house? Let us plan your next trip out with our Thriggle Events program (sponsored)! 
                  </p>
                  <p class="f19 font-weight-normal text-justify">
                 Our platform keeps you up to date with the discounts and deals happening in your area. Whether you’re trying to catch your favorite band or land a hometown discount, we’re your source for fun, affordable local events. Find the entertainment you want in the area you live with our events program!
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                  <img
                    src="/static/images/events-banner.jpg"
                    class="card-img-top"
                    style="height:470px"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Family Friendly  -->
        
        <div class="row" />
        <!-- Team Section -->
        <!-- <team :title="teamSectionTitle" :desc="teamSectionDesc" :data="teamMembers"></team> -->
        <div class="mt-5"> 
          <div class="container">
            <div class="card bg-gradient-warning shadow-lg border-0">
              <div class="p-5">
                <div class="row align-items-center">
                  <div class="col-lg-8">
                    <!-- <h3 class="text-white">Enjoy Unrestricted Entertainment on The Thriggle Neighborhood Entertainment Platform</h3> -->
                    <p class="lead text-white mt-3 font-weight:bold;">
                      Enjoy Unrestricted Entertainment on The Thriggle Neighborhood Entertainment Platform
                    </p>
                  </div>
                  <div class="col-lg-3 ml-lg-auto">
                    <router-link 
                    to="/login"
                      class="btn btn-lg btn-block btn-white"
                    >Join Now  </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import Team from 'Components/globalFrontendComponents/Team';
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
	components: {
		Team: Team,
		TitleBar: TitleBar
	},
	data(){
		return{
			teamSectionTitle :'The Amazing Team',
   		teamSectionDesc  :'According to the National Oceanic and Atmospheric Administration, Ted, Scambos, NSIDClead scentist, puts the potentially record maximum.',
   		teamMembers : [
                     {
                        name     : 'Ryan Tompson',
                        position : 'Web Developer',
                        image    : '/static/images/thumb-1.jpg'
                     },
                     {
                        name     : 'Romina Hadid',
                        position : 'Marketing Strategist',
                        image    : '/static/images/thumb-2.jpg'
                     },
                     {
                        name     : 'Alexander Smith',
                        position : 'UI/UX Designer',
                        image    : '/static/images/thumb-3.jpg'
                     },
                     {
                        name     : 'Ethan Moore',
                        position : 'Founder and CEO',
                        image    : '/static/images/thumb-4.jpg'
                     }
                    
                  ]
		}
	}
};
</script>
<style scoped>
.f19{
    font-size:19px;
}

#about_us {
    position: relative;
    padding: 70px 0;
    padding: 8rem 0;
    background: url(/static/images/about_us_bg.jpg);
    background-size: cover;
    background-position: center center;
    pointer-events: none;
    color: #fff;
}
</style>
